import {Component, inject, Input,} from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MediaPreviewService} from "../../../service/media-preview.service";

export enum ProfileAvatarSize {
  profile = 'profile', comment = 'comment', chat = 'chat', notification = 'notification', navigation = 'navigation'
}

@Component({
  selector: 'app-profile-avatar',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './profile-avatar.component.html',
  styleUrl: './profile-avatar.component.scss'
})
export class ProfileAvatarComponent {
  previewService = inject(MediaPreviewService);
  @Input({required: true}) url: string | null = null;
  @Input() size: string = ProfileAvatarSize.profile;
  @Input() online: boolean | null = null;
  @Input() preview: boolean = true;

  get sizePx() {
    switch (this.size) {
      case ProfileAvatarSize.chat:
        return 48;
      case ProfileAvatarSize.profile:
        return 80;
      case ProfileAvatarSize.comment:
      case ProfileAvatarSize.notification:
        return 40;
      case ProfileAvatarSize.navigation:
        return 32;
    }
    return 80;
  }

  open() {
    if (this.size == ProfileAvatarSize.profile && this.preview) {
      this.previewService.showImageFileUrl(this.url);
    }
  }
}
