<div class="profile-avatar" [ngClass]="['size-' + size, online ? 'online' : '']">
  <ng-container *ngTemplateOutlet="!!this.url ? fromUrl : placeholder"></ng-container>
</div>
<ng-template #fromUrl>
  <div class="profile-avatar-img" (click)="open()">
    <img [src]="url" alt="profile-avatar" srcset="" class="cover-fit" [width]="sizePx" [height]="sizePx">
  </div>
</ng-template>
<ng-template #placeholder>
  <div class="profile-avatar-img profile-avatar-placeholder"></div>
</ng-template>
